import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Content = styled(ParseContent)`
  text-align: justify;
  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.ultra};
      line-height: ${({ theme }) => theme.font.size.ultra};
    }
  }
`

const CustomContent = styled(ParseContent)`
  text-align: justify;
  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.ultra};
      line-height: ${({ theme }) => theme.font.size.ultra};
    }
  }

  @media (min-width: 992px){
    height: 260px;
  }
`

const StyledImageItem = styled(motion.div)`
  color: ${({ theme }) => theme.color.light};
  aspect-ratio: 1/1;
`

const StyledIconItem = styled.div`
  aspect-ratio: 1/1;
  color: #cfcfcf;
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(0.95);
  }

  & span {
    color: ${({ theme }) => theme.color.primary};
    @media (min-width: 1200px) {
      font-size: ${({ theme }) => theme.font.size.bigger};
    }
  }
`

const Image = styled(Plaatjie)`
  aspect-ratio: 1/1;
  object-fit: cover;
`

const Icon = styled(Plaatjie)`
  & img {
    object-fit: contain;
  }

  @media (min-width: 1200px) {
    height: 100px;
    width: 100px;
  }

  @media (max-width: 1199.98px) {
    height: 60px;
    width: 60px;
  }

  @media (max-width: 575.98px) {
    height: 40px;
    width: 40px;
  }
`

const Inner = styled.div`
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  & div > div {
    margin-left: 1rem;
    padding-right: 1rem;
    border-left: 2px solid ${({ theme }) => theme.color.light};
  }

  & span {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    word-wrap: break-word;
  }

  @media (min-width: 1200px) {
    & span {
      font-size: ${({ theme }) => theme.font.size.bigger};
    }
  }

  @media (max-width: 1199.98px) {
    & span {
      font-size: ${({ theme }) => theme.font.size.medium};
    }
  }

  @media (min-width: 576px) {
    & div > div {
      margin-left: 1rem;
      padding-right: 1rem;
    }
  }

  @media (max-width: 575.98px) {
    & span {
      font-size: ${({ theme }) => theme.font.size.tiny};
      width: 92%;
    }
  }
`

const Gradient = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  mix-blend-mode: multiply;
  background: linear-gradient(
    270.04deg,
    #263280 4.95%,
    rgba(38, 50, 128, 0.62) 92.79%
  );
`

const TextGrid: React.FC<TextProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  if (!fields.grid) return null

  const currentDescription = fields.grid[currentIndex]?.description

  return (
    <section className="mb-5 pb-lg-5">
      <div className="container py-5">
        <div className="row align-items-center justify-content-center">
          <div
            className={`col-lg-5 col-xl-6 mb-5 mb-lg-0 ${
              fields.alignment === 'left' ? 'order-2' : 'order-1'
            }`}
          >
            <div className="row">
              {fields.grid?.map((item, index) => (
                <div className="col-6 col-md-3 col-lg-6 mt-4" key={index}>
                  {item?.imagetype === 'image' && (
                    <ImageItem
                      item={item}
                      index={index}
                      setCurrentIndex={setCurrentIndex}
                      currentIndex={currentIndex}
                    />
                  )}
                  {item?.imagetype === 'icon' && (
                    <IconItem
                      item={item}
                      index={index}
                      currentIndex={currentIndex}
                      setCurrentIndex={setCurrentIndex}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div
            className={`col-lg-6 col-xl-5 ${
              fields.alignment === 'left' ? 'order-1' : 'order-2'
            }`}
          >
            <div
              className={`${
                fields.alignment === 'left' ? 'pe-sm-4' : 'ps-sm-4'
              }`}
            >
              <Content content={fields.description} className="pe-sm-5" />
              <CustomContent content={currentDescription} className="mt-5" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

interface ItemProps {
  item: any
  index?: number
  setCurrentIndex?: (index: number) => void
  currentIndex?: number
}

const ImageItem: React.FC<ItemProps> = ({
  item,
  index,
  setCurrentIndex,
  currentIndex,
}: any) => (
  <NavLink to={item?.link?.url || '#'} className="d-flex">
    <StyledImageItem
      className="position-relative w-100 h-100 overflow-hidden"
      onHoverStart={() => setCurrentIndex(index)}
      onHoverEnd={() => setCurrentIndex(-1)}
    >
      <Gradient className="position-absolute" />
      <motion.div
        initial={{ scale: 1 }}
        animate={currentIndex === index ? { scale: 1.05 } : { scale: 1 }}
      >
        <Image image={item?.image} alt="" loading="eager" objectFit="cover" />
      </motion.div>
      <Inner className="position-absolute h-100 w-100">
        <div
          className="d-inline-flex align-items-center"
          style={{ minHeight: '100%' }}
        >
          <div className="ms-sm-4">
            <span className="d-block ms-2">{item?.link?.title}</span>
          </div>
        </div>
      </Inner>
    </StyledImageItem>
  </NavLink>
)

const IconItem: React.FC<ItemProps> = ({
  item,
  index,
  currentIndex,
  setCurrentIndex,
}: any) => {
  return (
    <StyledIconItem className="position-relative" key={index}>
      {item.link.url === '#' ? (
        <button
          className="w-100 h-100 p-0"
          onClick={() => setCurrentIndex(index)}
        >
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <Icon image={item.icon} alt="" />
            <span className="px-2 px-xl-3 mx-xl-4 py-3 mt-2">
              {item?.link?.title}
            </span>
          </div>
        </button>
      ) : (
        <NavLink to={item?.link?.url || '#'} className="w-100 h-100">
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <Icon image={item.icon} alt="" />
            <span className="px-2 px-xl-3 mx-xl-4 py-3 mt-2">
              {item?.link?.title}
            </span>
          </div>
        </NavLink>
      )}
    </StyledIconItem>
  )
}

export default TextGrid
